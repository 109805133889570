import Lamora from "./lamora"
import flatpickr from "flatpickr"
require("flatpickr/dist/flatpickr.css")

Lamora.DatePicker = (function() {
  "use strict"

  flatpickr(".date-picker", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  })
})()
