"use strict"
import Lamora from "./lamora"

Lamora.Modal = {
  init: () => {
    Lamora.Modal.closeAllModals()
    Lamora.Modal.addCloseListeners()
    Lamora.Modal.addOpenListeners()
    Lamora.Modal.addOverlayListener()
  },

  addCloseListeners: () => {
    var links = document.querySelectorAll("a.modal__close-link")

    links.forEach(element =>
      element.addEventListener("click", (event) => {
        event.preventDefault()

        var currentModal = document.getElementById(element.dataset.modalId)
        Lamora.Modal.closeTargetModal(currentModal)
        Lamora.Modal.hide(Lamora.Modal.overlay)
      })
    )
  },

  addOpenListeners: () => {
    var links = document.querySelectorAll("a.modal-link")

    links.forEach(element =>
      element.addEventListener("click", (event) => {
        event.preventDefault()

        var currentModal = document.getElementById(element.dataset.modalId)

        Lamora.Modal.closeAllModals()
        Lamora.Modal.show(currentModal)
        Lamora.Modal.show(Lamora.Modal.overlay)
      })
    )
  },

  addOverlayListener: () => {
    Lamora.Modal.overlay.addEventListener("click", (event) => {
      event.preventDefault()

      Lamora.Modal.closeAllModals()
    })
  },

  closeAllModals: () => {
    var modals = document.querySelectorAll(".modal")

    modals.forEach(modal => Lamora.Modal.hide(modal))
    Lamora.Modal.hide(Lamora.Modal.overlay)
  },

  closeTargetModal: (target) => {
    Lamora.Modal.hide(target)
    Lamora.Modal.hide(Lamora.Modal.overlay)
  },

  hide: (target) => {
    target.classList.add("modal--hidden")
  },

  overlay: document.querySelector(".modal__overlay"),

  show: (target) => {
    target.classList.remove("modal--hidden")
  },
}

Lamora.Modal.init()
